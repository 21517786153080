import React from 'react';
import Logo from '../../resources/images/logo.svg';
import './styles.scss';

export default function Loading() {
    return <div className="loader">
        <img src={Logo} alt="logo"  />
        {/* <div className="outer"></div>
        <div className="middle"></div>
        <div className="inner"></div> */}
    </div>
}