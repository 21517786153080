import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Loading from '../components/Loading';

const Login = lazy(() => import('../views/Login'));
const Pruebas = lazy(() => import('../views/Pruebas'));
const Home = lazy(() => import('../views/Home'));
const PanelesDeAdministracion = lazy(() => import('../views/PanelesDeAdministracion'));
const VerCurso = lazy(() => import('../views/VerCurso'));

export default function App() {
	return (
		<BrowserRouter>
			<Suspense fallback={<Loading />}>
				<Routes>
					<Route exact path='/' element={<Login />} />
					<Route exact path='/pruebass' element={<Pruebas />} />
					<Route exact path='/home' element={<Home />} />
					<Route exact path='/paneles-de-administracion' element={<PanelesDeAdministracion />} />
					<Route exact path='/:id' element={<VerCurso />} />
				</Routes>
			</Suspense>
		</BrowserRouter>
	);
}
